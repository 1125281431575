<template>
   <div class="text-center " >

        <form class="form-signin" @submit.prevent>
            <img style="width:300px;height:auto;" src="../assets/logo.png"> 
            <h1 class="h3 mb-3 mt-3 font-weight-normal">Zaloguj się</h1>
            <input v-model.trim="loginForm.email" id="inputEmail" type="email" class="form-control" placeholder="Email" required autofocus>
            <input v-model.trim="loginForm.password" id="inputPassword" type="password" class="form-control" placeholder="Hasło" required autofocus>
            <div class="checkbox mb-3">
                <label>
                <input type="checkbox" value="remember-me"> Zapamiętaj mnie
                </label>
            </div>
            <button @click="login()" class="btn btn-lg btn-primary btn-block">Zaloguj się</button>
            <p class="mt-5 mb-3 text-muted">© 2025 <a href="https://2qbs.pl" target="_blank">2Qbs</a></p>
        </form>
 
    </div>

    
</template>
<script>

export default {
    data() {
        return {
            loginForm: {
                email: '',
                password: '',
            },
            signupForm: {
                name: '',
                title: '',
                email: '',
                password: ''
            },
            showLoginForm: true,
            showPasswordReset: false,
        }
    },
    methods: {
        toggleForm(){
            this.showLoginForm = !this.showLoginForm
        },
        tooglePasswordReset() {
            this.showPasswordReset = !this.showPasswordReset
        },
        login() {
            this.$store.dispatch('login', {
                email: this.loginForm.email,
                password: this.loginForm.password
            })
        },
        signup() {
            this.$store.dispatch('signup', {
                email: this.signupForm.email,
                password: this.signupForm.password,
                name: this.signupForm.name,
                title: this.signupForm.title
                
            })
        }
    }
    
}
</script>
<style scoped>
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}
#inputEmail{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top:10px;
    margin-bottom:2px
    
}
#inputPassword {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom:10px;
}
</style>